<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"

import DatePicker from "@vuepic/vue-datepicker"

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      title: this.$t('서버로그'),
      search: {
        level: '',
        key: '',
        beginDate: '',
        endDate: ''
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지
    }
  },
  mounted() {
    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 1)
    // let endDate = new Date()

    this.search.beginDate = beginDate
    // this.search.endDate = endDate

    const a = localStorage.getItem('limit')
    this.limit = a ? parseInt(a) : 50

    this.searchList().then()
  },
  methods: {
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      this.searchList().then()
    },
    goFirst() {
      this.page = 1
    },
    goPre() {
      if (this.page === 1) {
        this.page = 1
      } else {
        this.page -= 1
      }
    },
    goNext() {
      this.page += 1
    },
    searchByPage: function(page) {
      this.page = page
    },
    searchList: async function() {
      if (this.searchByUser === true && !this.user) return false

      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        localStorage.setItem('limit', this.limit)
        const params = {
          search: {level: this.search.level, key: this.search.key},
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText

        const result = await this.$API.log.server(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = result.documents

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  },
  watch: {
    page: function(val) {
      if (val) {
        this.searchList()
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div ref="contentWrapper">
              <form class="header-search" @submit.prevent="searchList">
                <!-- 페이징 및 검색 //-->
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group mb-2">
                      <DatePicker
                          v-model="search.beginDate"
                          :format="'yyyy-MM-dd HH:mm:ss'"
                          utc
                          auto-apply
                          :close-on-auto-apply="false"
                          :placeholder="$t('시작일')"
                      ></DatePicker>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group mb-2">
                      <DatePicker
                          v-model="search.endDate"
                          :format="'yyyy-MM-dd HH:mm:ss'"
                          utc
                          auto-apply
                          :close-on-auto-apply="false"
                          :placeholder="$t('종료일')"
                      ></DatePicker>
                    </div>
                  </div>
                  <div class="form-group col-md-2 mb-2">
                    <input type="text" class="form-control" v-model="search.key" placeholder="Key" />
                  </div>
                  <div class="form-group col-md-1 mb-2">
                    <select class="form-select" v-model="search.level">
                      <option value="">::{{ $t('로그레벨') }}::</option>
                      <option value="log">Log</option>
                      <option value="trace">Trace</option>
                      <option value="debug">Debug</option>
                      <option value="info">Info</option>
                      <option value="warn">Warn</option>
                      <option value="error">Error</option>
                    </select>
                  </div>
                  <div class="col-md-1 mb-2">
                    <select class="form-select" v-model="limit" @change="searchList">
                      <option :value="10">10개</option>
                      <option :value="25">25개</option>
                      <option :value="50">50개</option>
                      <option :value="100">100개</option>
                    </select>
                  </div>
                  <div class="col-md-3 mb-2">
                    <div class="form-group">
                      <div class="input-group mb-2">
                        <button type="button" class="btn btn-outline-success" @click="goFirst">처음</button>
                        <button type="button" class="btn btn-outline-primary" @click="goPre">이전</button>
                        <input type="number" class="form-control" min="1" v-model="page" />
                        <button type="button" class="btn btn-outline-info" @click="goNext">다음</button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-1 mb-2">
                    <button type="submit" class="btn btn-dark w-100">검색</button>
                  </div>
                </div>
              </form>

              <hr class="mt-0 mb-3" />

              <div id="table-wrapper" class="table-responsive mb-0 rounded-3" style="overflow: auto">
                <table class="table align-middle table-sm table-bordered table-nowrap sticky-table">
                  <thead class="table-dark">
                  <tr>
                    <th>Key</th>
                    <th>Log</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="record in list" :key="record.id">
                    <tr class="border-5 border-bottom" :class="{'bg-danger bg-opacity-25': record.level === 'error'}">
                      <td>
                        <div>
                          <span class="badge bg-dark" v-if="record.level === 'log'">{{ record.level }}</span>
                          <span class="badge bg-primary" v-if="record.level === 'trace'">{{ record.level }}</span>
                          <span class="badge bg-secondary" v-if="record.level === 'debug'">{{ record.level }}</span>
                          <span class="badge bg-info" v-if="record.level === 'info'">{{ record.level }}</span>
                          <span class="badge bg-warning" v-if="record.level === 'warn'">{{ record.level }}</span>
                          <span class="badge bg-danger" v-if="record.level === 'error'">{{ record.level }}</span>
                        </div>
                        <div>{{ record.key }}</div>
                        <div class="small">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</div>
                      </td>
                      <td class="ps-3 pt-3 pe-3">
                        <div>
                          <pre class="rounded-lg text-start" v-highlightjs><code style="max-height: 500px; overflow-y: auto;" class="javascript rounded" v-html="record.message"></code></pre>
                        </div>
                        <div>
                          <pre class="rounded-lg text-start" v-highlightjs><code style="max-height: 500px; overflow-y: auto;" class="javascript rounded" v-html="record.stack"></code></pre>
                        </div>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
